import React, { useRef } from "react";
import {} from "react-router-dom";
import Slider from "react-slick";

export default function Testimonials() {
  const sliderRef = useRef();

  let testimonial = {
    autoplay: true,
    arrows: true,
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    centerPadding: "0px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <section
        className="appie-testimonial-area py-5"
        style={{
          background: "#fff",
          padding: "20px 0px",
          height: "auto",
          display: "block",
        }}
      >
        <div className="container">
          <div className="row mb-5">
            <div className="col-lg-12">
              <div className="appie-section-title text-center">
                <h3 className="appie-title display-6 mb-1">Testimonials</h3>
                <p className="h4">What our community is saying</p>
              </div>
            </div>
          </div>
          <div className="appie-showcase-slider px30">
            <div className="pl-3 pr-3">
              <Slider ref={sliderRef} {...testimonial}>
                <div>
                  <div className="appie-features-content p-3">
                    <div className="contentBox p-3 d-flex flex-column justify-content-center align-items-center">
                      <div className="userBox">
                        <div className="userDetail">
                          <h5
                            style={{
                              height: "1.5rem",
                              lineHeight: "1.5rem",
                              overflow: "hidden",
                            }}
                          >
                            Anisha Patel
                          </h5>
                        </div>
                      </div>
                      <div className="writeupBox">
                        <p>
                          Securing commercial benefits for our innovation was
                          crucial for our business. Arthagrahana delivered
                          exceptional service, ensuring commercialization and
                          maximum utilization.
                        </p>
                      </div>
                      <div className="ratingBox mb-0 text-center">
                        <i className="fas fa-star text-warning" />
                        <i className="fas fa-star text-warning" />
                        <i className="fas fa-star text-warning" />
                        <i className="fas fa-star text-warning" />
                        <i className="fas fa-star text-warning" />
                        <b>5/5</b>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="appie-features-content p-3">
                    <div className="contentBox p-3 d-flex flex-column justify-content-center align-items-center">
                      <div className="userBox">
                        <div className="userDetail">
                          <h5
                            style={{
                              height: "1.5rem",
                              lineHeight: "1.5rem",
                              overflow: "hidden",
                            }}
                          >
                            Rohit Chandila
                          </h5>
                        </div>
                      </div>
                      <div className="writeupBox">
                        <p>
                          The team's professionalism and attention to detail was
                          impeccable.
                        </p>
                      </div>
                      <div className="ratingBox mb-0 text-center">
                        <i className="fas fa-star text-warning" />
                        <i className="fas fa-star text-warning" />
                        <i className="fas fa-star text-warning" />
                        <i className="fas fa-star text-warning" />
                        <i className="fas fa-star text-secondary" />
                        <b>4/5</b>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="appie-features-content p-3">
                    <div className="contentBox p-3 d-flex flex-column justify-content-center align-items-center">
                      <div className="userBox">
                        <div className="userDetail">
                          <h5
                            style={{
                              height: "1.5rem",
                              lineHeight: "1.5rem",
                              overflow: "hidden",
                            }}
                          >
                            Ravindra Choubey
                          </h5>
                        </div>
                      </div>
                      <div className="writeupBox">
                        <p>
                          Securing technology transfer for mutual benefits for
                          our innovations was crucial for our business.
                          Arthagrahana delivered exceptional service, ensuring
                          smooth transition of technology in most conclusive
                          manne.
                        </p>
                      </div>
                      <div className="ratingBox mb-0 text-center">
                        <i className="fas fa-star text-warning" />
                        <i className="fas fa-star text-warning" />
                        <i className="fas fa-star text-warning" />
                        <i className="fas fa-star text-warning" />
                        <i className="fas fa-star text-warning" />
                        <b>5/5</b>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <slide2 /> */}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
