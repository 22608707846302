import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Header from './component/Header';
import Footer from './component/Footer';

import Home from './pages/Home';
import Services from './pages/Services';
import Contact from './pages/Contact';
import Team from './pages/Team';
import About from './pages/About';

import TermCondition from './pages/Termsandcondition';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Faq from './pages/Faq';


function App() {
  return (
    <>
       <Router>
       <Header />
        <Routes>
            <Route path='/' Component={Home} />
            <Route path='/terms-and-condition' Component={TermCondition} />
            <Route path='/privacy-policy' Component={PrivacyPolicy} />
            <Route path='/faq' Component={Faq} />
            <Route path='/service' Component={Services} />
            <Route path='/contact' Component={Contact} />
            <Route path='/team' Component={Team} />
            <Route path='/about-us' Component={About} />
        </Routes>
        <Footer />
      </Router> 
    </>
  );
}

export default App;
