import React, { useRef } from "react";
import { useState } from "react";
import emailjs from "@emailjs/browser";
import {} from "react-router-dom";

export default function Contact() {
  // Form Data
  const [sellerFormData, setSellerFormData] = useState({
    name_sell: "",
    phone_sell: "",
    email_sell: "",
    message: "",
  });

  const [businessFormData, setBusinessFormData] = useState({
    name_bus: "",
    phone_bus: "",
    email_bus: "",
    message: "",
  });

  const handleSellerChange = (e) => {
    const { name, value } = e.target;
    setSellerFormData({ ...sellerFormData, [name]: value });
  };

  const handleBusinessChange = (e) => {
    const { name, value } = e.target;
    setBusinessFormData({ ...businessFormData, [name]: value });
  };

  // Form Error Data
  const [sellerFormErrors, setSellerFormErrors] = useState({
    nameError: "",
    emailError: "",
    phoneError: "",
    messageError: "",
  });

  const [businessFormErrors, setBusinessFormErrors] = useState({
    nameError: "",
    emailError: "",
    phoneError: "",
    messageError: "",
  });

  // Alertbox visibility
  const [visible, setVisible] = useState(false);

  const handleClose = () => {
    setVisible(false);
  };

  const formRefSeller = useRef();
  const formRefBusiness = useRef();

  const sendEmail_Seller = (e) => {
    e.preventDefault();

    if (!validateSellerForm()) return;

    emailjs
      .sendForm("service_q7jvq9q", "template_alcr7ir", formRefSeller.current, {
        publicKey: "Ig0PbhMXvc6Szj_GY",
      })
      .then(
        () => {
          // console.log('SUCCESS!');
          // Show Success message
          setVisible(true);
          setTimeout(() => setVisible(false), 3000);

          // Reset all fields
        },
        (error) => {
          console.log("FAILED...", error.text);
          alert("Form submission failed");
        }
      );
  };

  const sendEmail_Business = (e) => {
    e.preventDefault();

    if (!validateBusinessForm()) return;

    emailjs
      .sendForm(
        "service_q7jvq9q",
        "template_f80p9uj",
        formRefBusiness.current,
        {
          publicKey: "Ig0PbhMXvc6Szj_GY",
        }
      )
      .then(
        () => {
          // console.log('SUCCESS!');
          // Show success message
          setVisible(true);
          setTimeout(() => setVisible(false), 3000);

          // Reset all fields
        },
        (error) => {
          console.log("FAILED...", error.text);
          alert("Form Submission Failed");
        }
      );
  };

  const validateSellerForm = () => {
    // Perform validation for seller form fields

    let isValid = true;

    // Name validation
    if (!sellerFormData.name_sell.trim()) {
      setSellerFormErrors((prevErrors) => ({
        ...prevErrors,
        nameError: "Name should not be empty",
      }));
      isValid = false;
    } else if (!/^[a-zA-Z\s]*$/.test(sellerFormData.name_sell)) {
      setSellerFormErrors((prevErrors) => ({
        ...prevErrors,
        nameError: "Name should not contain numbers or special characters",
      }));
      isValid = false;
    } else {
      setSellerFormErrors((prevErrors) => ({ ...prevErrors, nameError: "" }));
    }

    // Email Validation
    if (!sellerFormData.email_sell.trim()) {
      setSellerFormErrors((prevErrors) => ({
        ...prevErrors,
        emailError: "Email should not be empty",
      }));
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(sellerFormData.email_sell)) {
      setSellerFormErrors((prevErrors) => ({
        ...prevErrors,
        emailError: "Please enter a valid email address",
      }));
      isValid = false;
    } else {
      setSellerFormErrors((prevErrors) => ({ ...prevErrors, emailError: "" }));
    }

    // Phone Validation
    if (!sellerFormData.phone_sell.trim()) {
      setSellerFormErrors((prevErrors) => ({
        ...prevErrors,
        phoneError: "Please enter your phone number",
      }));
      isValid = false;
    } else if (!/^\d+$/.test(sellerFormData.phone_sell.trim())) {
      setSellerFormErrors((prevErrors) => ({
        ...prevErrors,
        phoneError: "Phone number should only contain digits",
      }));
      isValid = false;
    } else {
      setSellerFormErrors((prevErrors) => ({ ...prevErrors, phoneError: "" }));
    }

    // Message Validation
    if (!sellerFormData.message.trim()) {
      setSellerFormErrors((prevErrors) => ({
        ...prevErrors,
        messageError: "Please enter your message",
      }));
      isValid = false;
    } else {
      setSellerFormErrors((prevErrors) => ({
        ...prevErrors,
        messageError: "",
      }));
    }

    // console.log(sellerFormErrors);
    return isValid;
  };

  const validateBusinessForm = () => {
    // Perform validation for business form fields

    let isValid = true;

    // Name validation
    if (!businessFormData.name_bus.trim()) {
      setBusinessFormErrors((prevErrors) => ({
        ...prevErrors,
        nameError: "Name should not be empty",
      }));
      isValid = false;
    } else if (!/^[a-zA-Z\s]*$/.test(businessFormData.name_bus)) {
      setBusinessFormErrors((prevErrors) => ({
        ...prevErrors,
        nameError: "Name should not contain numbers or special characters",
      }));
      isValid = false;
    } else {
      setBusinessFormErrors((prevErrors) => ({ ...prevErrors, nameError: "" }));
    }

    // Email Validation
    if (!businessFormData.email_bus.trim()) {
      setBusinessFormErrors((prevErrors) => ({
        ...prevErrors,
        emailError: "Email should not be empty",
      }));
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(businessFormData.email_bus)) {
      setBusinessFormErrors((prevErrors) => ({
        ...prevErrors,
        emailError: "Please enter a valid email address",
      }));
      isValid = false;
    } else {
      setBusinessFormErrors((prevErrors) => ({
        ...prevErrors,
        emailError: "",
      }));
    }

    // Phone Validation
    if (!businessFormData.phone_bus.trim()) {
      setBusinessFormErrors((prevErrors) => ({
        ...prevErrors,
        phoneError: "Please enter your phone number",
      }));
      isValid = false;
    } else if (!/^\d+$/.test(businessFormData.phone_bus.trim())) {
      setBusinessFormErrors((prevErrors) => ({
        ...prevErrors,
        phoneError: "Phone number should only contain digits",
      }));
      isValid = false;
    } else {
      setBusinessFormErrors((prevErrors) => ({
        ...prevErrors,
        phoneError: "",
      }));
    }

    // Message Validation
    if (!businessFormData.message.trim()) {
      setBusinessFormErrors((prevErrors) => ({
        ...prevErrors,
        messageError: "Please enter a message",
      }));
      isValid = false;
    } else {
      setBusinessFormErrors((prevErrors) => ({
        ...prevErrors,
        messageError: "",
      }));
    }

    // console.log(businessFormErrors);
    return isValid;
  };

  return (
    <>
      {visible && (
        <div className="alert-box" id="alertBox">
          <div className="row">
            <p className="py-2 m-auto" id="info">
              🎉 Message Delivered!
            </p>
            <svg
              className="m-auto"
              id="cancelBtn"
              aria-hidden="true"
              viewBox="0 0 14 16"
              onClick={handleClose}
            >
              <path
                fill-rule="evenodd"
                d="M7.71 8.23l3.75 3.75-1.48 1.48-3.75-3.75-3.75 3.75L1 11.98l3.75-3.75L1 4.48 2.48 3l3.75 3.75L9.98 3l1.48 1.48-3.75 3.75z"
              ></path>
            </svg>
          </div>

          <div className="row">
            <div
              role="progressbar"
              aria-hidden="false"
              aria-label="notification timer"
              className="Toastify__progress-bar Toastify__progress-bar--animated Toastify__progress-bar-theme--light Toastify__progress-bar--success"
              style={{
                animationDuration: "3000ms",
                animationPlayState: "play",
                opacity: 1,
              }}
            ></div>
          </div>
        </div>
      )}

      <section className="works my-lg-5 " id="Sec_Contact">
        <div className="row p-2">
          <div>
            <h2 className="display-4 text-center">How It Works</h2>
          </div>

          <p className="text text-center fz15">
            Ready to take the next step in commercializing your patent or
            finding the technology your business needs? Place Your Request.
          </p>
        </div>

        <div className="row pt30">
          {/* Seller Form */}
          <div className="col-lg-6 border p-4 p-md-5" id="sellers">
            <div className="seller-box">
              <div className="main-title">
                <h2 className="display-6 text-center text-light">
                  For Patent Holders <br className="d-none d-lg-block" />{" "}
                </h2>
              </div>

              <form
                class="contact_form grid"
                id="contact-form"
                ref={formRefSeller}
                onSubmit={sendEmail_Seller}
              >
                <div id="alert" class="alert_style"></div>

                <div class="contact_inputs grid">
                  <div class="contact_content mb-2">
                    <input
                      type="text"
                      name="name_sell"
                      class="contact_input"
                      placeholder="Name"
                      id="name_sell"
                      required
                      value={sellerFormData.name_sell}
                      onChange={handleSellerChange}
                    ></input>
                  </div>
                  {sellerFormErrors.nameError && (
                    <div className="error-message">
                      {sellerFormErrors.nameError}
                    </div>
                  )}
                  <div class="contact_content mb-2">
                    <input
                      type="tel"
                      name="phone_sell"
                      class="contact_input"
                      placeholder="Phone number"
                      id="phone_sell"
                      required
                      value={sellerFormData.phone_sell}
                      onChange={handleSellerChange}
                    ></input>
                  </div>
                  {sellerFormErrors.phoneError && (
                    <div className="error-message">
                      {sellerFormErrors.phoneError}
                    </div>
                  )}
                  <div class="contact_content mb-2">
                    <input
                      type="email"
                      name="email_sell"
                      class="contact_input"
                      placeholder="Email"
                      id="email_sell"
                      required
                      value={sellerFormData.email_sell}
                      onChange={handleSellerChange}
                    ></input>
                  </div>
                  {sellerFormErrors.emailError && (
                    <div className="error-message">
                      {sellerFormErrors.emailError}
                    </div>
                  )}
                  <div class="contact_content mb-2">
                    <textarea
                      name="message"
                      id="Reviewmessage_sell"
                      cols="0"
                      rows="1"
                      class="contact_input message"
                      placeholder="Brief Description of the Invention"
                      required
                      value={sellerFormData.message}
                      onChange={handleSellerChange}
                    ></textarea>
                  </div>
                  {sellerFormErrors.messageError && (
                    <div className="error-message">
                      {sellerFormErrors.messageError}
                    </div>
                  )}
                </div>

                <div className="my-5">
                  <button
                    class="works-btn"
                    id="sell_btn"
                    type="submit"
                    value="Send"
                  >
                    <span className="button_top">Get In Touch</span>
                  </button>
                </div>
              </form>
            </div>
          </div>

          {/* Business Form */}
          <div className="col-lg-6 border p-4 p-md-5" id="business-entities">
            <div className="business-box">
              <div className="main-title">
                <h2 className="display-6 text-center">
                  For Business Entities
                  <br className="d-none d-lg-block" />{" "}
                </h2>
              </div>

              <form
                class="contact_form grid"
                id="contact-form"
                ref={formRefBusiness}
                onSubmit={sendEmail_Business}
              >
                <div id="alert" class="alert_style"></div>

                <div class="contact_inputs grid">
                  <div class="contact_content mb-2">
                    <input
                      type="text"
                      name="name_bus"
                      class="contact_input"
                      placeholder="Name"
                      id="name_bus"
                      required
                      value={businessFormData.name_bus}
                      onChange={handleBusinessChange}
                    ></input>
                  </div>
                  {businessFormErrors.nameError && (
                    <div className="error-message">
                      {businessFormErrors.nameError}
                    </div>
                  )}
                  <div class="contact_content mb-2">
                    <input
                      type="tel"
                      name="phone_bus"
                      class="contact_input"
                      placeholder="Phone number"
                      id="phone_bus"
                      required
                      value={businessFormData.phone_bus}
                      onChange={handleBusinessChange}
                    ></input>
                  </div>
                  {businessFormErrors.phoneError && (
                    <div className="error-message">
                      {businessFormErrors.phoneError}
                    </div>
                  )}
                  <div class="contact_content mb-2">
                    <input
                      type="email"
                      name="email_bus"
                      class="contact_input"
                      placeholder="Email"
                      id="email_bus"
                      required
                      value={businessFormData.email_bus}
                      onChange={handleBusinessChange}
                    ></input>
                  </div>
                  {businessFormErrors.emailError && (
                    <div className="error-message">
                      {businessFormErrors.emailError}
                    </div>
                  )}
                  <div class="contact_content mb-2">
                    <textarea
                      name="message"
                      id="Reviewmessage_bus"
                      cols="0"
                      rows="1"
                      class="contact_input message"
                      placeholder="Technology Requirement"
                      required
                      value={businessFormData.message}
                      onChange={handleBusinessChange}
                    ></textarea>
                  </div>
                  {businessFormErrors.messageError && (
                    <div className="error-message">
                      {businessFormErrors.messageError}
                    </div>
                  )}
                </div>

                <div className="my-5">
                  <button
                    class="works-btn"
                    id="bus_btn"
                    type="submit"
                    value="Send"
                  >
                    <span className="button_top">Get In Touch</span>
                  </button>

                  <p>
                    <small id="error-msg"></small>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Details */}
      <section className="cta-home3-last pt50 pb90 bdrs24">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-6 col-lg-7 col-xl-7 wow fadeInLeft">
              <div className="cta-style3">
                <h2 className="cta-title mb-5">Contact Us</h2>
                <p className="cta-text mb-4">
                  Whether you're looking to sell your innovative creations or
                  seeking cutting-edge technologies to enhance your business,
                  we're here to facilitate the process. Connect with us and
                  embark on a journey of innovation, collaboration, and
                  commercial success!
                </p>

                <div className="phone-contact d-flex flex-row justify-content-start">
                  <span className="flaticon-call mx-2"></span>
                  <p className="text mx-2">Phone: +91 9871745789</p>
                </div>
                <div className="email-contact d-flex flex-row justify-content-start">
                  <span className="flaticon-mail mx-2"></span>
                  <p className="text mx-2">Email: info@arthagrahana.com</p>
                </div>
                <div className="email-contact d-flex flex-row justify-content-start">
                  <span className="flaticon-tracking mx-2"></span>
                  <p className="text mx-2">Address: D-33/34, Freedom Fighter Enclave, Neb Sarai, Delhi - 110068</p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-5 col-xl-5 position-relative wow zoomIn">
              <div className="cta-img">
                <img
                  className="w-100"
                  src="images/about/about-contact.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
