import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <>
            <section class="footer-style1 at-home10 pt25 pb-0 footback" >
                <div class="container">
                    {/* <div class="row bb-white-light pb10 mb60"> */}
                        {/* <div class="col-md-7"> */}
                            {/* <div class="d-block text-center text-md-start justify-content-center justify-content-md-start d-md-flex align-items-center mb-3 mb-md-0"> */}
                                {/* <Link class="fz17 fw500 text-dark mr15-md mr30" to="/terms-and-condition">Terms of Service</Link > */}
                                {/* <Link class="fz17 fw500 text-dark mr15-md mr30" to="/privacy-policy">Privacy Policy</Link > */}
                                {/* <a href="#Sec_Faq" class="fz17 fw500 text-dark mr15-md mr30">Faq's</a> */}
                        
                            {/* </div> */}
                        {/* </div> */}
                        {/* <div class="col-md-5"> */}
                            {/* <div class="social-widget text-center text-md-end"> */}
                                {/* <div class="social-style1"> */}
                                    {/* <Link class="text-dark me-2 fw500 fz17" to="#">Follow us</Link > */}
                                    {/* <Link to="#"><i style={{filter:"invert(1)"}} class="fab fa-facebook-f list-inline-item"></i></Link > */}
                                    {/* <Link to="#"><i style={{filter:"invert(1)"}} class="fab fa-linkedin-in list-inline-item"></i></Link > */}
                                {/* </div> */}
                            {/* </div> */}
                        {/* </div> */}
                    {/* </div> */}
                    <div class="row text-center align-items-center pt-5">
                        <div class="col-sm-4 col-lg-4">
                            <div class="link-style1 mb-sm-4">
                                <Link className="navbar-brand" to="/"><img src="/images/logo_dark.png" style={{width:'8vw',height:'8vw',objectFit:'contain'}} className="logo" alt="..." /></Link>
                                <div class="link-list text-dark h4">
                                    {/* तमसो मा ज्योतिर्गमय */}
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 col-lg-4">
                            <div class="link-style1 mb-sm-4">
                                <h5 class="text-dark mb15">Categories</h5>
                                <ul class="ps-0">
                                    <li><a href="#carouselDemo">Home</a></li>
                                    <li><a href="#Sec_About">About</a></li>
                                    <li><a href="#main-services">Services</a></li>
                                    <li><a href="#Sec_Contact">Contact</a></li>
                                    <li><a href="#Sec_Faq">Faq</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-sm-4 col-lg-4">
                            <div class="link-style1 mb-sm-4">
                                <h5 class="text-dark mb15">Support</h5>
                                <ul class="ps-0">
                                    <li><a href="#Sec_Contact">Help & Support</a ></li>
                                    {/* <li><Link to="#">Trust & Safety</Link ></li> */}
                                    {/* <li><Link to="#">Selling on Arthagrahana</Link ></li> */}
                                    {/* <li><Link to="#">Buying on Arthagrahana</Link ></li> */}
                            
                                </ul>
                            </div>
                        </div>

                        {/* <div class="col-sm-6 col-lg-3">
                            <div class="footer-widget">
                                <div class="footer-widget mb-4 mb-sm-5">
                                    <div class="mailchimp-widget">
                                        <h5 class="title text-dark mb20">Subscribe</h5>
                                        <div class="mailchimp-style1">
                                            <input type="email" class="form-control" placeholder="Your email address" />
                                            <button type="submit">Send</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                    </div>
                </div>

                <div class="container white-bdrt1 py-4">
                    <div class="text-center text-lg-start">
                        <p class="copyright-text mb-2 mb-md-0 text-dark-light ff-heading">© Arthagrahana. 2024 All rights reserved.</p>
                    </div>
                </div>
                        
            </section>
            <Link class="scrollToHome" to="#"><i class="fas fa-angle-up"></i></Link >
            <div />
            <div />
        </>
    )
}

export default Footer