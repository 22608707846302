import React from "react";
import { useState } from "react";
import {} from "react-router-dom";

export default function About() {
  let galleryData = [
    {
      id: 1,
      imgSrc: "images/about/about-1.svg",
    },
    {
      id: 2,
      imgSrc: "images/about/about-2.svg",
    },
    {
      id: 4,
      imgSrc: "images/about/about-3.svg",
    },
  ];

  const [tempImgSrc, setTempImgSrc] = useState("images/about/about-1.svg");

  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc);
  };

  return (
    <>
      <section className="row my-5 p-2 align-items-center" id="Sec_About">
        <div className="col-md-2 gallery d-none d-lg-block">
          {galleryData.map((item, index) => {
            return (
              <div
                className="pics"
                key={index}
                onClick={() => getImg(item.imgSrc)}
              >
                <img className="w-75" src={item.imgSrc} alt="About_Images" />
              </div>
            );
          })}
        </div>

        <div className="col-md-6 col-lg-4 mainImg">
          <img className="w-100" src={tempImgSrc} alt="About_Img Selected" />
        </div>

        <div className="col-md-6 p-4 p-lg-3 d-flex flex-column justify-content-center">
          <div className="main-title">
            <h2 className="display-4 text-center">
              About Us <br className="d-none d-lg-block" />{" "}
            </h2>
          </div>

          <p className="text text-justify mb-4 fz15">
            ArthaGrahana is your trusted partner committed to accelarate the
            commercialization of patents and new technologies.
          </p>
          <p className="text text-justify mb-4 fz15">
            Our focus extends beyond mere connections – we specialize in
            bridging the gap between innovators, institutions, and organizations
            with the business entities seeking cutting-edge advancements.
          </p>
          <p className="text text-justify mb-0 fz15">
            At ArthaGrahana, we understand the importance of fostering
            collaboration and facilitating the seamless transfer of knowledge to
            drive progress and growth.
          </p>

          <div className="my-5">
            <a class="tp-btn" href="#Sec_Feature">
              <span>Discover More</span>
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
