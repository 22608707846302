import React from "react";
import {} from "react-router-dom";

export default function Mission() {
  return (
    <>
      <section className="p-0 mt50" id="Sec_Mission">
        <div className="cta-banner mx-auto maxw1600 py-5 position-relative overflow-hidden d-flex justify-content-center align-items-center">
          <div className="container-fluid">
            <div className="row align-items-center p-5">
              <div
                className="col-lg-7 pl30-md pl15-xs wow fadeInRight"
                data-wow-delay="500ms"
              >
                <div className="mb30">
                  <div className="main-title">
                    <h2 className="title display-4">
                      Our Vision & Mission <br className="d-none d-lg-block" />{" "}
                    </h2>
                  </div>
                </div>
                <div className="why-chose-list">
                  <div className="list-one d-flex align-items-start mb30">
                    <span className="list-icon my-4 flex-shrink-0 flaticon-badge" />
                    <div className="list-content flex-grow-1 ml20">
                      <h4 className="my-4">VISION</h4>
                      <p className="text mb-2 fz15">
                        To foster innovation and empower businesses by providing
                        comprehensive <strong>solutions</strong> for technology
                        transfer, enabling <strong>growth</strong> and{" "}
                        <strong>success</strong> in a dynamic global landscape.
                      </p>
                      <p className="text mb-0 fz15">
                        Through our expertise and dedication, we strive to
                        contribute to the promotion of
                        <strong> technological innovation</strong> and transfer
                        and dissemination of technology for mutual benefit of
                        producer and user of technology.
                      </p>
                    </div>
                  </div>
                  <div className="list-one d-flex align-items-start mb30">
                    <span className="list-icon my-4 flex-shrink-0 flaticon-goal" />
                    <div className="list-content flex-grow-1 ml20">
                      <h4 className="my-4">MISSION</h4>
                      <p className="text mb-2 fz15">
                        We aim to match <strong>innovators</strong> with those
                        seeking new technologies to enhance{" "}
                        <strong>efficacy</strong> and{" "}
                        <strong>grow their businesses</strong>.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-5 flex-grow-1 wow fadeInLeft"
                data-wow-delay="500ms"
              >
                <div className="about-img row justify-content-center">
                  <img
                    className="col-md-8 col-lg-12"
                    src="images/about/about-mission.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
