import React,{useState} from "react";
import { Link } from "react-router-dom";

const Header = () => {

  const [display,setDisplay]=useState(false);

  const toggleDisplay = () => {
    setDisplay(!display);
  };

  return (
    <>
      <div>
        <header className="header-nav nav-innerpage-style stricky main-menu border-0">
          {/* Ace Responsive Menu */}
          <nav className="posr">
            <div className="container posr menu_bdrt1">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-12">
                  <div className="row align-items-center justify-content-between">
                    <div className="d-flex flex-row justify-content-center align-items-center logos mr20 col-lg-3">
                      <Link className="header-logo logo2" to="/">
                        <img src="images/logo_dark.png" alt="Header Logo" />
                      </Link>
                      <p className="nav-brand-name m-auto">Arthagrahana</p>
                    </div>

                    {/* Responsive Menu Structure*/}
                    <ul
                      id="respMenu"
                      className="ace-responsive-menu col-lg-9 w-50"
                      data-menu-style="horizontal"
                    >
                      <li className="visible_lis">
                        <a className="list-item" href="#carouselDemo">
                          <span className="title">HOME</span>
                        </a>
                      </li>

                      <li className="visible_lis">
                        <a className="list-item" href="#Sec_About">
                          <span className="title">ABOUT</span>
                        </a>
                      </li>

                      <li className="visible_lis">
                        <a className="list-item" href="#main-services">
                          <span className="title">SERVICES</span>
                        </a>
                      </li>

                      <li className="visible_lis">
                        <a className="list-item" href="#Sec_Contact">
                          <span className="title">CONTACT</span>
                        </a>
                      </li>

                      <li className="visible_lis">
                        <a className="list-item" href="#Sec_Faq">
                          <span className="title">FAQ</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <div className="col-auto">
                                    <div className="d-flex align-items-center">
                                        <Link className="login-info mx15-lg mx30" to="page-become-seller.html"><span className="d-none d-xl-inline-block">Become a</span> Seller</Link>
                                        <Link className="login-info mr15-lg mr30" to="page-login.html">Sign in</Link>
                                        <Link className="ud-btn btn-white2 add-joining at-home10" to="page-register.html">Join</Link>
                                    </div>
                                </div> */}
              </div>
            </div>
          </nav>
        </header>
        {/* Search Modal */}
        {/* <div className="search-modal">
                    <div className="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex={-1}>
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                <h5 classN="modal-title" id="exampleModalToggleLabel">a</h5>
                                 <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class="fal fa-xmark"></i></button>
                                </div>
                                <div className="modal-body">
                                    <div className="popup-search-field search_area">
                                        <input type="text" className="form-control border-0" placeholder="What service are you looking for today?" />
                                        <label><span className="far fa-magnifying-glass" /></label>
                                        <button className="ud-btn btn-thm" type="submit">Search</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
        <div className="hiddenbar-body-ovelay" />
        {/* Mobile Nav  */}
        <div id="page" className="mobilie_header_nav stylehome1">
          <div className="mobile-menu">
            <div className="header bdrb1">
              <div className="menu_and_widgets">
                <div className="mobile_menu_bar d-flex justify-content-between align-items-center">
                  <Link className="mobile_logo" to="/">
                    <img
                      style={{ width: "35px", height: "" }}
                      src="images/logo_dark.png"
                      alt="Header Logo"
                    />
                  </Link>
                  <div className="right-side text-end" onClick={toggleDisplay}>
                    {/* <Link className to="page-login.html">join</Link> */}
                    <span className="menubar ml30">
                      <img src="images/mobile-dark-nav-icon.svg" alt="" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="posr">
                <div className="mobile_menu_close_btn">
                  <span className="far fa-times" />
                </div>
              </div>
            </div>
          </div>
          {/* /.mobile-menu */}
          {display && (<nav id="menu">
            <ul className="d-flex flex-column align-items-center justify-content-center">
              <a href="#carouselDemo">
                <li>
                  <span>Home</span>
                </li>
              </a>
              <a href="#Sec_About">
                <li>
                  <span>About</span>
                </li>
              </a>
              <a href="#main-services">
                <li>
                  <span>Services</span>
                </li>
              </a>
              <a href="#Sec_Contact">
                <li>
                  <span>Contact</span>
                </li>
              </a>
              <a href="#Sec_Faq">
                <li>
                  <span>Faq</span>
                </li>
              </a>
              
              {/* Only for Mobile View */}
            </ul>
          </nav>
          )}
        </div>
      </div>
      <div className="body_content" />
    </>
  );
};

export default Header;
