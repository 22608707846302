import React from 'react';
import { } from 'react-router-dom';

import Carousel from '../component/Carousel';
import About from '../component/About';
import Services from '../component/Services';
import WhyChoose from '../component/WhyChoose';
import Process from '../component/Process';
import Features from '../component/Features';
import Mission from '../component/Mission';
// import Team from '../component/Team';
// import Partner from '../component/Partner';
import Contact from '../component/Contact';
import Testimonials from '../component/Testimonials';
import Faq from '../component/Faq';

export default function Home(){

  return (
    <>
      <div>
        <Carousel />
        <About />
        <Services />
        <WhyChoose />
        <Process />
        <Features />
        <Mission />
        {/* <Team /> */}
        {/* <Partner /> */}
        <Contact />
        <Testimonials />
        <Faq />

        </div>
    </>
  )
}