import React, { useRef } from "react";
import {} from "react-router-dom";
import Slider from "react-slick";

export default function WhyChoose() {
  const sliderRef = useRef();

  let why_chooose = {
    autoplay: true,
    arrows: false,
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    centerPadding: "0px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {/* Why Choose Us Section */}

      <section className="services-one" style={{ padding: "60px 0" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="appie-section-title text-center mb-5">
                <h3 className="appie-title display-6 mb-1">Why Choose Us</h3>
                <p className="h4">Reasons why our clients love us</p>
              </div>
            </div>
          </div>

          <div className=" services-one_carousel">
            <div className="pl-3 pr-3">
              <Slider ref={sliderRef} {...why_chooose}>
                <div className="service-block">
                  <div>
                    <div className="service-block-icon">
                      <span className="flaticon-working"></span>
                    </div>
                    <h4 className="service-block-heading">
                      <a href="service-detail.html">Expert Technical Team</a>
                    </h4>
                    <div className="why-choose-text">
                      Our experienced technical team, combined with industry
                      experts, ensures a thorough evaluation of patents and
                      technologies.
                    </div>
                  </div>
                </div>

                <div className="service-block">
                  <div>
                    <div className="service-block-icon">
                      <span className="flaticon-search-1"></span>
                    </div>
                    <h4 className="service-block-heading">
                      <a href="service-detail.html">Strategic Insights</a>
                    </h4>
                    {/* <div className="why-choose-text">Make informed decisions with our comprehensive validity checks and patent/technology valuations, ensuring that your innovations are not only ground-breaking but also commercially viable.</div> */}
                    <div className="why-choose-text">
                      Make informed decisions with our comprehensive validity
                      checks and patent/technology valuations, ensuring that
                      your innovations are ground-breaking and commercially
                      viable.
                    </div>
                  </div>
                </div>

                <div className="service-block">
                  <div>
                    <div className="service-block-icon">
                      <span className="flaticon-digital-marketing"></span>
                    </div>
                    <h4 className="service-block-heading">
                      <a href="service-detail.html">
                        Extensive Business Network
                      </a>
                    </h4>
                    <div className="why-choose-text">
                      We are connected with a diverse range of business
                      entities, providing ample opportunities for inventors and
                      business entities to collaborate.
                    </div>
                  </div>
                </div>

                <div className="service-block">
                  <div>
                    <div className="service-block-icon">
                      <span className="flaticon-rocket"></span>
                    </div>
                    <h4 className="service-block-heading">
                      <a href="service-detail.html">Focused on Growth</a>
                    </h4>
                    <div className="why-choose-text">
                      Our goal is to help business entities grow by
                      incorporating cutting-edge technologies, driving
                      efficiency and innovation.
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
