import React from "react";
import {} from "react-router-dom";

export default function Services() {
  return (
    <>
      <section className="services-one py-5" id="main-services">
        <div className="container">
          <div className="appie-section-title text-center mb-5">
            <h3 className="display-4 text-dark mb-3">Our Services</h3>
            <p className="h4 text-dark">Explore Creative Solutions</p>
          </div>

          <div className="d-flex flex-row justify-content-center flex-wrap">
            <div className="service-card my-2 mx-2">
              <div className="card service-bg border px-4 pt-5 pb-3 h-100">
                <div className="upper-box mb-4">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="service-block_number">01</div>
                    <div className="service-block_icon p-2">
                      <span className="flaticon-factory"></span>
                    </div>
                  </div>
                </div>

                <h4 className="service-block_heading mb-3 mb-md-0">
                  Commercialization of Patent
                </h4>
                <div className="service-block-text d-none d-md-block my-4">
                  We specialize in bringing patented inventions to market,
                  connecting inventors with business entities eager to integrate
                  new technologies into their operations. We ensure that
                  groundbreaking technologies find their way into the market and
                  assist in creating mutually beneficial partnerships.
                </div>

                <div className="lower-box pt-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="footer-icon">
                      <span className="flaticon-badge"></span>
                    </div>
                    <div className="service-block_more">Know More</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="service-card my-2 mx-2">
              <div className="card service-bg border px-4 pt-5 pb-3 h-100">
                <div className="upper-box mb-4">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="service-block_number">02</div>
                    <div className="service-block_icon p-2">
                      <span className="flaticon-question"></span>
                    </div>
                  </div>
                </div>

                <h4 className="service-block_heading mb-3 mb-md-0">
                  Providing Technical Know-How
                </h4>
                <div className="service-block-text d-none d-md-block my-4">
                  Our team is dedicated to provide comprehensive technical
                  support and ensures a smooth transition for businesses
                  entities adopting new technologies.
                </div>

                <div className="lower-box pt-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="footer-icon">
                      <span className="flaticon-badge"></span>
                    </div>
                    <div className="service-block_more">Know More</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-row justify-content-center flex-wrap">
            <div className="service-card my-2 mx-2">
              <div className="card service-bg border px-4 pt-5 pb-3 h-100">
                <div className="upper-box mb-4">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="service-block_number">03</div>
                    <div className="service-block_icon p-2">
                      <span className="flaticon-review"></span>
                    </div>
                  </div>
                </div>

                <h4 className="service-block_heading mb-3 mb-md-0">
                  Validity Checks of Invention and Patent
                </h4>
                <div className="service-block-text d-none d-md-block my-4">
                  We conduct thorough validity checks to assess the novelty and
                  patentability of claims and provide a reliable evaluation of
                  the commercial viability of patented inventions and ensure
                  that your innovation stand the test of scrutiny and maximize
                  the commercial potential.
                </div>

                <div className="lower-box pt-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="footer-icon">
                      <span className="flaticon-badge"></span>
                    </div>
                    <div className="service-block_more">Know More</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="service-card my-2 mx-2">
              <div className="card service-bg border px-4 pt-5 pb-3 h-100">
                <div className="upper-box mb-4">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="service-block_number">04</div>
                    <div className="service-block_icon p-2">
                      <span className="flaticon-money"></span>
                    </div>
                  </div>
                </div>

                <h4 className="service-block_heading mb-3 mb-md-0">
                  Valuation of Patent/Technology
                </h4>
                <div className="service-block-text d-none d-md-block my-4">
                  We assess the commercial viability of patents, technologies
                  and offer valuable insights to guide decision-making
                  processes.
                </div>

                <div className="lower-box pt-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="footer-icon">
                      <span className="flaticon-badge"></span>
                    </div>
                    <div className="service-block_more">Know More</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
