import React from "react";
import {} from "react-router-dom";

export default function Carousel() {
  return (
    <>
      <div
        id="carouselDemo"
        className="carousel slide "
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselDemo"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselDemo"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
        </div>

        <div className="carousel-inner ">
          <div
            className="carousel-item active"
            id="carousel-1"
            data-bs-interval="5000"
          >
            {/* <img src="https://www.allthingsdistributed.com/images/2024-predictions-header.png" className="d-block w-100" alt="..." /> */}
            <img
              src="images/carousel-1.svg"
              className="d-block mx-auto my-5"
              style={{
                display: "block",
                // margin: "3rem auto",
                maxWidth: "700px",
                width: "100%",
                height:"70%",
                maxHeight:"320px",
                backgroundColor: "#DEF7E5",
              }}
              alt="..."
            />
            <div className="carousel-caption my-4">
              <h1 className=" display-6 text-dark">
                Unlock the Power of Innovation
              </h1>
              <p className="h6 text-dark">
                Commercialize Your Patents and Technologies with Us!
              </p>
            </div>
          </div>
          <div
            className="carousel-item "
            id="carousel-2"
            data-bs-interval="3000"
          >
            {/* <img src="https://images.unsplash.com/photo-1589828994379-7a8869c4f519?q=80&w=1824&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" className="d-block w-100" style={{display:"block",margin:"auto"}} alt="..." /> */}
            <img
              src="images/carousel-2.svg"
              className="d-block mx-auto my-5"
              style={{
                display: "block",
                // margin: "auto",
                width: "100%",
                maxWidth: "700px",
                height:"75%",
                maxHeight:"320px",
                backgroundColor: "#57A8FF",
              }}
              alt="..."
            />
            {/* <img src="images/home2.jpg" className="d-block w-100" alt="..." /> */}
            <div className="carousel-caption my-3">
              {/* <h1 className='display-6'>Second slide label</h1> */}
              <p className="h6 text-white text-justify">
                Are you an innovator, institution, or organization with
                groundbreaking technologies waiting to make a mark in the
                commercial world? Look no further! We are your dedicated
                partners in the journey of commercializing your innovation.
              </p>
            </div>
          </div>

          {/* 
              <div className="carousel-item" data-bs-interval="3000">
                <img src="https://images.unsplash.com/photo-1589828994379-7a8869c4f519?q=80&w=1824&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" className="d-block w-100" alt="..." />
                <div className="carousel-caption ">
                  <h1 className='display-6'>Third slide label</h1>
                  <p className='h5'>Some representative placeholder content for the third slide.</p>
                </div>
              </div>
               */}
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselDemo"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselDemo"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </>
  );
}
