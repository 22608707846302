import React from "react";
import {} from "react-router-dom";

export default function Features() {
  return (
    <>
      <section
        className="cta-banner-about2 mt50 at-home10-2 mx-auto position-relative p-md-4"
        id="Sec_Feature"
      >
        <div className="container">
          <div
            className="row align-items-center wow fadeInDown"
            data-wow-delay="400ms"
          >
            <div className="col-lg-7 col-xl-5 wow fadeInRight">
              <div className="mb30">
                <div className="main-title">
                  <h2 className="title">
                    Innovating Excellence: Patent Solutions for the Modern Age
                  </h2>
                </div>
              </div>
              <div className="why-chose-list">
                <div className="list-one d-flex align-items-start mb30">
                  <span className="list-icon flex-shrink-0 flaticon-badge" />
                  <div className="list-content flex-grow-1 ml20">
                    <h4 className="mb-1">Industry-Specific Expertise</h4>
                    <p className="text mb-0 fz15">
                      Specialized knowledge and insight tailored to our clients'
                      specific fields.
                    </p>
                  </div>
                </div>
                <div className="list-one d-flex align-items-start mb30">
                  <span className="list-icon flex-shrink-0 flaticon-money" />
                  <div className="list-content flex-grow-1 ml20">
                    <h4 className="mb-1">
                      Proactive Patent Strategy Consultation
                    </h4>
                    <p className="text mb-0 fz15">
                      Develop comprehensive patent strategies that align with
                      their business objectives.
                    </p>
                  </div>
                </div>
                <div className="list-one d-flex align-items-start mb30">
                  <span className="list-icon flex-shrink-0 flaticon-security" />
                  <div className="list-content flex-grow-1 ml20">
                    <h4 className="mb-1">Exceptional Customer Service</h4>
                    <p className="text mb-0 fz15">
                      Prompt responsiveness, clear communication, and
                      personalized attention every step of the way.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-lg-5 col-xl-4 offset-xl-1 wow fadeInLeft">
                            <div className="listbox-style1 px30 py-5 bdrs16 bgc-dark position-relative">
                                <div className="list-style1">
                                    <ul className="mb-0">
                                        <li className="text-white fw500"><i className="far fa-check dark-color bgc-white" />Quality work done quickly</li>
                                        <li className="text-white fw500"><i className="far fa-check dark-color bgc-white" />Technology Integration</li>
                                        <li className="text-white fw500"><i className="far fa-check dark-color bgc-white" />Dedicated Client Portal</li>
                                        <li className="text-white fw500 mb-0"><i className="far fa-check dark-color bgc-white" />Support whenever you need it</li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}
          </div>
        </div>
        <img
          className="home10-cta-img bdrs24 d-none d-lg-block"
          src="images/about/about-feature.svg"
          alt=""
        />
      </section>
    </>
  );
}
