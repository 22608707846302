import React from 'react';
import { } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';

export default function Faq() {


    return (
        <>
            <div className="pb-5 py-5" id='Sec_Faq' style={{ backgroundColor: '#f5f5f5', marginTop: '0px !important' }}>
                <div className="container pb-5">

                    <div className="row">
                        <div className="col-lg-12">

                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">FAQ’s</h3>
                                <small>Frequently Asked Questions.</small>
                            </div>

                        </div>
                    </div>

                    <Accordion>
                        <Accordion.Item eventKey="0" className='my-3 rounded' >
                            <Accordion.Header className='py-3'>What are the obligations of Patentee after grant of patent?</Accordion.Header>
                            <Accordion.Body>
                                After the grant of patent, every patentee has to maintain the patent by paying renewal fee every year as prescribed in schedule-I. For first two years, there is no renewal fee. The renewal fee is payable from 3rd year onward. In case renewal fee is not paid, the patent will be ceased.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1" className='my-3 rounded' >
                            <Accordion.Header className='py-3'>What are working statements for the granted patent?</Accordion.Header>
                            <Accordion.Body>
                            The patentees have to file working/non-working statements for the granted patents, which are in force through Form-27.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2" className='my-3 rounded' >
                            <Accordion.Header className='py-3'>What issues are to be considered before collaborating with a company?</Accordion.Header>
                            <Accordion.Body>
                                The inventor must enter into a properly drafted Non Disclosure Agreement (NDA) with the company before initiating discussions to ensure that the invention is protected from unfair exploitation. If the collaborator and/or the company seek material for testing and evaluation, a properly drafted Material Transfer Agreement (MTA) must be signed or a commercial evaluation license or an internal commercial use license may be signed.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3" className='my-3 rounded' >
                            <Accordion.Header className='py-3'>What is Patent Commercialization?</Accordion.Header>
                            <Accordion.Body>
                                The commercialization of a patent means that the owner of the patent has either sold the patent for a fair value, licensed the patent, or introduced a replacement product on the market based on the patent. In short, it means that the owner has financially profited from the patent in some manner.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4" className='my-3 rounded'>
                            <Accordion.Header className='py-3'>What is licensing</Accordion.Header>
                            <Accordion.Body>
                                A license is a legal agreement by which the owner of an invention (licensor) grants rights to the licensee to make, use, and/or sell the invention within the framework of license agreement. Any entity that aims to manufacture and market a patented product needs a license from the licensor.
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="5" className='my-3 rounded'>
                            <Accordion.Header className='py-3'>Why should you license a technology?</Accordion.Header>
                            <Accordion.Body>
                                Licensing an intellectual property helps in ensuring commercialization of an invention, bring the product to market for public use, generates revenue to the inventor(s) and the agency and enhances the image of the organization as the product carries the details of the licensor.
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="6" className='my-3 rounded'>
                            <Accordion.Header className='py-3'>What is technology transfer?</Accordion.Header>
                            <Accordion.Body>
                            Technology transfer is the formal transfer of IP or other rights to any entity to use and commercialize new inventions.
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="7" className='my-3 rounded'>
                            <Accordion.Header className='py-3'>What is Freedom to Operate (FTO)?</Accordion.Header>
                            <Accordion.Body>
                            Before commercializing a product in a country or region, generally a Freedom to operate search is suggested to confirm that the IP/technology being licensed does not infringe IP technology of another party. FTO helps avoid legal action by others

                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>
        </>
    )
}    
