import React from "react";
import {} from "react-router-dom";

export default function Process() {
  return (
    <>
      <section
        className="cta-banner-about2 at-home10 mx-auto position-relative pt60-lg pb60-lg mt50"
        id="Sec_Process"
      >
        <img
          className="cta-about2-img at-home10 bdrs24 d-none d-xl-block"
          src="images/about/about-9.jpg"
          alt=""
        />
        {/* <img className="cta-about2-img at-home10 bdrs24 d-none d-xl-block w-50" src="images/about/about-process.svg" alt="" /> */}

        <div className="container">
          <div className="row">
            <div
              className="col-xl-9 offset-xl-3 wow fadeInUp"
              data-wow-delay="200ms"
            >
              <div className="main-title text-center text-lg-end">
                <h6 className="display-6 title text-capitalize">
                  Looking for a Partnership?
                </h6>
                <p className="h4">Just follow these simple steps</p>
              </div>
            </div>
          </div>
          <div className="row wow fadeInDown" data-wow-delay="400ms">
            <div className="col-xl-9 offset-xl-3">
              <div className="row">
                <div className="col-lg-4">
                  <div className="iconbox-style9 default-box-shadow1 bgc-white p40 bdrs12 position-relative mb30">
                    <span className="icon fz40 flaticon-chat" />
                    <h4 className="iconbox-title mt20">Initial Consultation</h4>
                    <p className="text mb-0">
                      We start with a friendly chat to understand your invention
                      and requirements. Tell us about it, and we will guide you
                      with comprehensive solutions.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="iconbox-style9 default-box-shadow1 bgc-white p40 bdrs12 position-relative mb30">
                    <span className="icon fz40 flaticon-contract" />
                    <h4 className="iconbox-title mt20">Evaluation</h4>
                    <p className="text mb-0">
                      We evaluate commercial potential of the invention and draft documnet describing your invention clearly and persuasively, highlighting what makes it special.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="iconbox-style9 default-box-shadow1 bgc-white p40 bdrs12 position-relative mb30">
                    <span className="icon fz40 flaticon-success" />
                    <h4 className="iconbox-title mt20">Facilitation</h4>
                    <p className="text mb-0">
                      We facilitate commercial utilization of invention. You now
                      now have a peace of mind and a competitive edge in the
                      market.✅
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
